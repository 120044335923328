
































































import PreviewAsset from '@/calendesk/models/PreviewAsset'
import { mapActions } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
export default mixins(DraftElement).extend({
  name: 'Carousel',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      carousel: 0,
      carouselItem: new Array<Array<string>>()
    }
  },
  mounted () {
    this.generatePortfolioImages()
  },
  methods: {
    ...mapActions({
      openPreviewAsset: 'preview/openPreviewAsset'
    }),
    openPreviewAssetHandle (url: string) {
      this.openPreviewAsset(new PreviewAsset(true, url))
    },
    prev (): void {
      if (this.carousel === 0) {
        this.carousel = this.data.images.length - 1
        return
      }
      this.carousel--
    },
    next (): void {
      if (this.carousel === this.data.images.length - 1) {
        this.carousel = 0
        return
      }
      this.carousel++
    },
    goTo (index: number): void {
      this.carousel = index
    },
    generatePortfolioImages (): void {
      const n = 3
      const items = new Array<string>()
      this.data.images.forEach((img: any) => {
        items.push(`${this.appConfiguration.imageBaseUrl}${img.name}`)
      })
      this.carouselItem = new Array(Math.ceil(items.length / n))
        .fill('')
        .map(() => items.splice(0, n))
    }
  }
})
